import React from 'react';
import {useIntl} from 'react-intl';
import {CircularProgress, Grid, TextField, Typography} from '@material-ui/core';
import {DateTimePicker, EzFormControl, YesNoRadioGroup} from 'wobi-web-common';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import SimulatorExpansionPanel from '../../components/SimulatorComponents/ExpansionPanel';
import useStyles from './useStyles';

const FollowUpCall = React.memo(({
  handleDateChange,
  formikSetField,
  formikValues,
  isFollowUpLoading,
  maxDate,
  minDate,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});

  const handlePhoneNumberChange = (event) => {
    const {value} = event.target;
    formikSetField('followupPhoneNumber', value);
  };

  const handleIsSaleInProgressChange = (value) => {
    const isSaleInProgress = value === 'yes';
    formikSetField('isSaleInProgress', isSaleInProgress);
  };

  const disableHolidays = (date) => {
    const FRIDAY_DAY = 5;
    const SATURDAY_DAY = 6;
    const day = moment(date).day();
    if (day === FRIDAY_DAY || day === SATURDAY_DAY) {
      return true;
    }

    return false;
  };

  return (
    <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.followup.call')}</Typography>}>
      <Grid style={{rowGap: 12}} container spacing={0}>
        {isFollowUpLoading ?
          <Grid item style={{
            alignItems: 'center',
            display: 'flex',
            height: 38,
            justifyContent: 'center',
            width: '100%',
          }} >
            <CircularProgress size={20} />
          </Grid> : (
            <>
              <Grid container>
                <Grid item xs={4} className={classes.subtitle}>
                  {messages('simulator.title.needs.followup.call')}
                </Grid>
                <Grid item xs={8}>
                  <EzFormControl name='isSaleInProgress' isLabelInChild>
                    <YesNoRadioGroup
                      value={formikValues.isSaleInProgress ? 'yes' : formikValues.isSaleInProgress === null ? '' : 'no'}
                      onChange={
                        (event, value) => handleIsSaleInProgressChange(value)
                      }
                    />
                  </EzFormControl>
                </Grid>
              </Grid>
              {formikValues.isSaleInProgress || formikValues.isSaleInProgress === null ? null : (
                <Grid container spacing={0} style={{rowGap: 12}} >
                  <Grid item xs={4} className={classes.subtitle}>
                    {messages('simulator.title.followup.call.schedule.date')}
                  </Grid>
                  <Grid item xs={4}>
                    <EzFormControl
                      name='followupCallDate'
                      label={messages('simulator.title.followup.call.schedule.date.label')}
                      isLabelInChild
                    >
                      <DateTimePicker
                        ampm={false}
                        value={formikValues.followupCallDate}
                        onChange={(date) => handleDateChange(date)}
                        onAccept={() => {}}
                        autoOk
                        id='followupCallDate'
                        openTo='date'
                        shouldDisableDate={disableHolidays}
                        maxDate={maxDate}
                        minDate={minDate}
                        variant='inline'
                        size='small'
                      />
                    </EzFormControl>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4} className={classes.subtitle}>
                      {messages('simulator.title.followup.call.phone.number')}
                    </Grid>
                    <Grid item xs={4}>
                      <EzFormControl
                        name='followupPhoneNumber'
                        label={messages('simulator.title.followup.call.phone.number.label')}
                        isLabelInChild
                      >
                        <TextField
                          id='followupPhoneNumber'
                          autoComplete='off'
                          label='טלפון'
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                            handlePhoneNumberChange(e);
                          }}
                          value={formikValues.followupPhoneNumber}
                          size='small'
                        />
                      </EzFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
      </Grid>
    </SimulatorExpansionPanel>
  );
});

FollowUpCall.propTypes = {
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
  handleDateChange: PropTypes.func,
  isAgentAllowedToFollowupCall: PropTypes.bool,
  isFollowUpLoading: PropTypes.bool,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
};

export default FollowUpCall;
