import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Grid, MenuItem, TextField, CircularProgress, FormHelperText} from '@material-ui/core';
import {EzFormControl, YesNoRadioGroup} from 'wobi-web-common';
import carBrandData from 'wobi-web-common/dist/assets/js/carBrandsData';
import useStyles from '../useStyles';
import {
  getAutoBrandsAndYears,
  getAutoModelsAndSubModels,
  getLicenseInfoSimulator,
} from '../../../utils/apiHandlers';
import SmallSelect from '../../../components/SimulatorComponents/SmallSelect';
import SimulatorExpansionPanel from '../../../components/SimulatorComponents/ExpansionPanel';
import {getSimulatorOffersFields} from '../../../utils/persistOfferDetails';

const AutoInfoDetails = React.memo(({
  formikHandleChange,
  formikSetField,
  formikValues,
  autoInfo,
  setAutoInfo,
  setLicenseNumberValidationCallback,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});

  // State
  const [brandsAndYears, setBrandsAndYears] = useState({});
  const [checkEspText, setCheckEspText] = useState(formikValues.esp === 'yes');
  const [modelsAndSubModels, setModelsAndSubModels] = useState({});
  const [years, setYears] = useState(autoInfo ?
    [{
      text: autoInfo.car_year,
      value: autoInfo.car_year.toString(),
    }] :
    []);
  const [models, setModels] = useState(autoInfo ?
    [{
      text: autoInfo.main_car_desc,
      value: autoInfo.car_model,
    }] :
    []);
  const [subModels, setSubModels] = useState(autoInfo ?
    [{
      text: setAutoDesc(autoInfo),
      value: `${autoInfo.car_model}${autoInfo.car_year}`,
    }] :
    []);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardataFailed, setIsCardataFailed] = useState('');
  const [isCarSubModelInactive, setIsCarSubModelInactive] = useState(false);
  const [isExists, setIsExists] = useState(
    getSimulatorOffersFields('isExistingCustomer')?.length > 0 ?
      getSimulatorOffersFields('isExistingCustomer').includes('רכב- פעיל') :
      false,
  );
  const [isRenewal, setIsRenewal] = useState(false);
  const renderError = (message) => (<FormHelperText error>{message}</FormHelperText>);

  if (!Object.entries(brandsAndYears).length) {
    fetchBrandsAndYears(setBrandsAndYears);
  }

  const handleAutoInfoSelects = async (fieldName, value) => {
    if (fieldName !== 'brand') {
      formikSetField(fieldName, value.toString());
    }

    switch (fieldName) {
    case 'brand': {
      const brand = value && value.value ? value.value.toString() : value ? value.toString() : value;
      formikSetField('brand', value);

      if (value) {
        const brandsYears = brandsAndYears[brand].years
          .map(year => ({text: year,
            value: year}));
        setYears(brandsYears);
      } else {
        setYears([]);
      }

      setCheckEspText(false);
      setModels([]);
      setSubModels([]);
      if (formikValues.year) {
        formikSetField('year', '');
      }
      if (formikValues.model) {
        formikSetField('model', '');
      }
      if (formikValues.subModel) {
        formikSetField('subModel', '');
        formikSetField('leviModel', '');
      }
      break;
    }
    case 'year': {
      const data = {brand: formikValues.brand,
        year: value};
      getAutoModelsAndSubModels(data).then(resp => {
        setModels(generateModelsOptions(resp));

        // After setting it goes on React.useEffect [modelsAndSubModels]
        setModelsAndSubModels(resp);

        setCheckEspText(false);
        setSubModels([]);
        if (formikValues.model) {
          formikSetField('model', '');
        }

        // If check because of formikSetField could be executed after
        // model was set on React.useEffect [modelsAndSubModels]
        if (formikValues.subModel) {
          formikSetField('subModel', '');
          formikSetField('leviModel', '');
        }
      });
      break;
    }
    case 'model': {
      setSubModels(generateSubModelsOptions(modelsAndSubModels, value));

      if (formikValues.subModel) {
        formikSetField('subModel', '');
        formikSetField('leviModel', '');
      }
      setCheckEspText(false);
      break;
    }
    case 'subModel': {
      const model = modelsAndSubModels[formikValues.model] || {};
      const {subModels: subs} = model;
      if (subs && subs[value]) {
        const hasEsp = subs[value].esp === 1;
        setAutoInfo({...subs[value], eq_level: null});
        formikSetField('hasEspClientAnswer', hasEsp);
        setCheckEspText(hasEsp);
        formikSetField('esp', hasEsp ? 'yes' : '');
        formikSetField('leviModel', subs[value].car_model);
        setLicenseNumberValidationCallback(!subs[value].active);
        setIsCarSubModelInactive(!subs[value].active);
        setIsCardataFailed(false);
      }
      break;
    }
    default:
      break;
    }
  };

  const handleGetCardata = () => {
    const validLicenseNumber =
      formikValues.licenseNumber &&
      formikValues.licenseNumber.length <= 8 &&
      formikValues.licenseNumber.length >= 5;
    if (validLicenseNumber) {
      setIsLoading(true);
      getLicenseInfoSimulator(formikValues.licenseNumber, formikValues.phone).then(resp => {
        setIsLoading(false);
        if (resp.success) {
          setCheckEspText(false);
          formikSetField('hasEspClientAnswer', '');
          setIsCardataFailed(false);

          const isExistingCustomer = resp.data.policy?.isActive;
          setIsExists(isExistingCustomer);
          setIsRenewal(resp.data.policy?.isRenewal);

          if (!resp.data.policyId) {
            const respAutoInfo = resp.data;
            if (respAutoInfo.esp !== null) {
              formikSetField('esp', respAutoInfo.esp === 1 ? 'yes' : 'no');
            }
            if (respAutoInfo.eq_level >= 7) {
              formikSetField('ldw', 'yes');
              formikSetField('fcw', 'yes');
            } else {
              if (respAutoInfo.fcw !== null) {
                formikSetField('fcw', respAutoInfo.fcw === 1 ? 'yes' : 'no');
              }
              if (respAutoInfo.ldw !== null) {
                formikSetField('ldw', respAutoInfo.ldw === 1 ? 'yes' : 'no');
              }
            }
            formikSetField('leviModel', respAutoInfo.car_model);
            setAutoInfo(respAutoInfo);
            setCheckEspText(respAutoInfo.esp === 1);
            formikSetField('hasEspClientAnswer', respAutoInfo.esp === 1 ? 'yes' : '');
            if (!carBrandData.find(brand => brand.value === respAutoInfo.manufacturer_id)) {
              carBrandData.push({
                label: respAutoInfo.manufacturer,
                value: respAutoInfo.manufacturer_id,
              });
            }

            formikSetField('brand', respAutoInfo.manufacturer_id);
            setYears([{
              text: respAutoInfo.car_year,
              value: respAutoInfo.car_year.toString(),
            }]);
            formikSetField('year', respAutoInfo.car_year.toString());

            // Need to wait till formik 'year' field updates and useEffect [formikValues] finished
            // (setModelsAndSubModels is calling in process of year updating)
            // otherwise will have wrong handleAutoInfoSelects chain: year->subModel->model
            setTimeout(() => {
              setModels([{
                text: respAutoInfo.main_car_desc,
                value: respAutoInfo.car_model,
              }]);
              formikSetField('model', respAutoInfo.car_model);
              setSubModels([{
                text: setAutoDesc(respAutoInfo),
                value: `${respAutoInfo.car_model}${respAutoInfo.car_year}`,
              }]);
              formikSetField('submodel', `${respAutoInfo.car_model}${respAutoInfo.car_year}`);
              setIsCarSubModelInactive(!respAutoInfo.active);
              formikSetField('isCarInactive', !respAutoInfo.active);
            }, 500);
          }
        } else {
          setIsCardataFailed(resp.message ? resp.message :
            resp.exception.error ? resp.exception.error :
              JSON.stringify(resp));
        }
      }).catch((error) => {
        setIsLoading(false);
        let message = 'wobi-auto-service failed';
        if (error.message.includes('The user aborted a request.')) {
          message = 'Timeout error (request took too long)';
        }
        setIsCardataFailed(message);
      });
    } else {
      setIsCardataFailed('מספר רישוי צריך להיות בין 5 ל-8 תווים');
    }
  };

  React.useEffect(() => {
    if (formikValues.brand && autoInfo && !formikValues.year) {
      handleAutoInfoSelects('year', autoInfo.car_year);
    }
  }, [formikValues]);
  React.useEffect(() => {
    if (
      formikValues?.autoInfo &&
      !carBrandData.find(brand => brand.value === formikValues?.autoInfo?.manufacturer_id)
    ) {
      carBrandData.push({
        label: formikValues?.autoInfo?.manufacturer,
        value: formikValues?.autoInfo?.manufacturer_id,
      });
    }
  }, []);

  React.useEffect(() => {
    if (Object.entries(modelsAndSubModels).length && autoInfo) {
      handleAutoInfoSelects('model', autoInfo.car_model);
    }
  }, [modelsAndSubModels]);

  React.useEffect(() => {
    if (Object.entries(subModels).length && autoInfo) {
      handleAutoInfoSelects('subModel', `${autoInfo.car_model}${autoInfo.car_year}`);
    }
  }, [subModels]);
  const handleLicenseNumberChange = (e) => {
    e.target.value = e.target.value.replace(/^0+/, '');
    formikHandleChange(e);
  };

  return (
    <SimulatorExpansionPanel title={<FormattedMessage tagName='span' id='auto_info.fill_auto_info'/>}>
      <div>
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <Button
              onClick={handleGetCardata}
              color='secondary'
              variant='outlined'
              size='small'
            >
              {isLoading ? <CircularProgress size={20}/> : messages('simulator.get_cardata')}
            </Button>
          </Grid>
          {isExists && <Grid item><p>{messages('simulator.customer_exists')}</p></Grid>}
          {isRenewal && <Grid item><p>{messages('simulator.renewal')}</p></Grid>}
        </Grid>
        {isCardataFailed ? renderError(isCardataFailed) : ''}
        {isCarSubModelInactive ? renderError(messages('simulator.inactive_model')) : ''}
        <div style={{marginTop: 10}}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <EzFormControl
                name='licenseNumber'
                label={intl.formatMessage({id: 'fields.licenseNumber'})}
                isLabelInChild
              >
                <TextField
                  id='outlined-required'
                  className={`${classes.fullWidth}`}
                  onChange={handleLicenseNumberChange}
                  value={formikValues.licenseNumber}
                  variant='outlined'
                  size='small'
                />
              </EzFormControl>
            </Grid>
            <Grid item xs={4}>
              <SmallSelect
                value={formikValues.brand}
                onChange={({target: {value}}) => handleAutoInfoSelects('brand', value)}
                name='brand'
                label={messages('fields.brand')}
              >
                {carBrandData.map(brand => (
                  <MenuItem key={`brand-${brand.value}`} value={brand.value}>
                    {brand.label}
                  </MenuItem>
                ))}
              </SmallSelect>
            </Grid>
            <Grid item xs={4}>
              <EzFormControl name='leviModel' label='' isLabelInChild>
                <TextField
                  id='outlined-disabled'
                  className={`${classes.fullWidth}`}
                  value={formikValues.leviModel}
                  variant='outlined'
                  disabled
                  size='small'
                />
              </EzFormControl>
            </Grid>
            <Grid item xs={4}>
              <SmallSelect
                disabled={!years.length}
                value={formikValues.year}
                onChange={({target: {value}}) => {
                  formikSetField('hasEspClientAnswer', '');
                  formikSetField('esp', '');
                  return handleAutoInfoSelects('year', value);
                }}
                name='year'
                label={messages('fields.year')}
              >
                {years.map(year => (
                  <MenuItem key={`year-${year.value}`} value={year.value}>
                    {year.text}
                  </MenuItem>
                ))}
              </SmallSelect>
            </Grid>
            <Grid item xs={4}>
              <SmallSelect
                disabled={!models.length}
                value={formikValues.model || models.length && models.find(m => m.text === formikValues.carModel)?.value}
                onChange={({target: {value}}) => handleAutoInfoSelects('model', value)}
                name='model'
                label={messages('fields.model')}
              >
                {models.map(model => (
                  <MenuItem key={`model-${model.value}`} value={model.value}>
                    {model.text}
                  </MenuItem>
                ))}
              </SmallSelect>
            </Grid>
            <Grid item xs={4}>
              <SmallSelect
                disabled={!subModels.length}
                value={formikValues.subModel}
                onChange={({target: {value}}) => handleAutoInfoSelects('subModel', value)}
                name='subModel'
                label={messages('fields.subModel')}
              >
                {subModels.map(subModel => (
                  <MenuItem key={`subModel-${subModel.value}`} value={subModel.value}>
                    {subModel.text}
                  </MenuItem>
                ))}
              </SmallSelect>
            </Grid>
            <Grid xs={12} item container spacing={1}>
              <Grid item xs={5} container direction='column'>
                <Grid item className={classes.subtitle}>{messages('auto_info.ldw')}</Grid>
                <Grid item>
                  <EzFormControl name='ldw'>
                    <YesNoRadioGroup value={formikValues.ldw} onChange={formikHandleChange}/>
                  </EzFormControl>
                </Grid>
              </Grid>
              <Grid item xs={4} container direction='column'>
                <Grid item className={classes.subtitle}>{messages('auto_info.fcw')}</Grid>
                <Grid item>
                  <EzFormControl name='fcw'>
                    <YesNoRadioGroup value={formikValues.fcw} onChange={formikHandleChange}/>
                  </EzFormControl>
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column' justify='flex-end'>
                <Grid item className={classes.subtitle}>
                  <span>
                    ESP
                    {checkEspText && (<span style={{color: 'red', marginRight: 2}}>
                      { messages('auto_info.checkEsp')
                      }
                    </span>)}
                  </span>
                </Grid>
                <Grid item>
                  <EzFormControl name='esp'>
                    <YesNoRadioGroup value={formikValues.esp} onChange={(event) => {
                      formikHandleChange(event);
                      if (autoInfo) {
                        autoInfo.esp = event.target.value === 'yes' ? 1 : 0;
                        setAutoInfo(autoInfo);
                      }
                      if (checkEspText) {
                        formikSetField('hasEspClientAnswer', event.target.value === 'yes');
                      }
                    }}/>
                  </EzFormControl>
                </Grid>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={10} className={classes.subtitle}>
                <b>
                  {
                    formikValues.ldw === 'yes' && formikValues.fcw === 'yes' &&
                      autoInfo?.eq_level >= 7 &&
                      !isCarSubModelInactive && !isCardataFailed ?
                      messages('auto_info.tooltip_text_ldw_fcw_yes_all_simulator') : false
                  }
                </b>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </SimulatorExpansionPanel>
  );
});

const fetchBrandsAndYears = (setBrandsAndYears) => {
  getAutoBrandsAndYears().then(resp => {
    if (Object.entries(resp).length) {
      setBrandsAndYears(resp);
    }
  });
};

const generateModelsOptions = (modelsAndSubmodels) => Object.values(modelsAndSubmodels)
  .map(model => ({text: model.main_car_desc,
    value: model.car_model}))
  .sort((a, b) => a.text.localeCompare(b.text));

const generateSubModelsOptions = (modelsAndSubModels, model) => {
  const modelObject = modelsAndSubModels[model];
  if (!modelObject) {
    return [];
  }
  return Object.values(modelObject.subModels)
    .map(subModel => ({
      text:
        setAutoDesc(subModel),
      value: subModel.id,
    }));
};

const setAutoDesc = (subModel) => {
  const desc1 = setAutoDescEscaped(subModel.model_desc_1);
  const desc2 = setAutoDescEscaped(subModel.model_desc_2);
  const desc3 = setAutoDescEscaped(subModel.model_desc_3);
  return `${desc1} ${desc2} ${desc3}`;
};

const setAutoDescEscaped = (desc) => desc.startsWith('"') && desc.endsWith('"') ?
  desc.slice(1, -1).replace('"', '') : desc;

AutoInfoDetails.propTypes = {
  autoInfo: PropTypes.object,
  className: PropTypes.string,
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
  potentialId: PropTypes.string,
  setAutoInfo: PropTypes.func,
  setLicenseNumberValidationCallback: PropTypes.object,
};

export default AutoInfoDetails;
