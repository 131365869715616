import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {Grid, Box, Table, TableCell, TableRow, TableBody,
  Typography, Link, Button, SnackbarContent, Icon, makeStyles} from '@material-ui/core';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import ShippingDetails from '../components/ShippingDetails';
import {getOffersFields, getSelectedOffer} from '../utils/persistOfferDetails';
import {backendServicePrefix} from '../utils/apiHelpers';
import {getImageByAgent} from '../config/agent';

const alertImage = getImageByAgent('alert.svg');

const OrderApproval = () => {
  const classes = useStyles();
  const [isSippingOpen, setIsShippingOpen] = useState(true);
  const setIsShippingClose = () => {
    setIsShippingOpen(false);
  };

  const maleFemaleSelectOptions = {
    1: 'male',
    2: 'female',
  };

  const policyDetails = {
    autoInfo: getOffersFields('autoInfo'),
    cardHolder: getOffersFields('cardHolder'),
    driversCount: getOffersFields('driversCount'),
    endDate: getOffersFields('endDate'),
    firstname: getOffersFields('firstname'),
    lastname: getOffersFields('lastname'),
    licenseNumber: getOffersFields('licenseNumber'),
    offersCallId: getOffersFields('offersCallId'),
    orderId: sessionStorage.getItem('orderId'),
    ownerFullName: getOffersFields('ownerFullName'),
    productType: getOffersFields('policyType').toLowerCase(),
    selected_offer: getSelectedOffer(),
    startDate: getOffersFields('startDate'),
    youngDriverAge: getOffersFields('youngDriverAge'),
    youngDriverGender: maleFemaleSelectOptions[getOffersFields('youngDriverGender')],
  };

  return (<Grid container>
    <Grid item xs={12} lg={6}>
      <Box mb={4} pb={4} borderBottom={1} className={classes.borderColorLight}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.messageHead} component='h1'>
              <FormattedMessage id='order_approval.tank_you'/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.messageBigLetters}><FormattedMessage id='order_approval.success'/></Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.messageBigLetters}>
              <FormattedMessage id='order_approval.order_number_text'/>
              <span className={classes.colorAccent}>
                {' '}
                {policyDetails.orderId}
              </span>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    <Grid item xs={12} lg={7}>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography className={classes.messageRegular}>
                <FormattedMessage id='order_approval.compulsory_message'/>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.messageRegular}>
                <FormattedMessage id='order_approval.shipping_message'/>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={1}>
              <Typography className={classes.messageSubhead}>
                <FormattedMessage id='order_approval.contact_message'/>
                {' '}
                <Link className={`${classes.messageSubhead} ${classes.colorAccent}`}>
                  <FormattedMessage id='order_approval.contact_link'/>
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box px={4} py={3} mb={1} className={classes.boxAlert}>
              <Box className={classes.boxAlertTitle}>
                <img className={classes.vAlignMiddle} src={alertImage}
                  alt={<FormattedMessage id='order_approval.alert_title'/>}/>
                <span>
                  {' '}
                  <FormattedMessage id='order_approval.alert_title'/>
                </span>
              </Box>
              <Box className={classes.boxAlertMessage}><FormattedMessage id='order_approval.alert_message'/></Box>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={2}>
              <Typography className={classes.messageSubhead}>
                <strong><FormattedMessage id='order_approval.table_title'/></strong>
              </Typography>
            </Box>
            <Box>
              <Table className={classes.tableSmall} size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='order_approval.table_label_period'/>
                    </TableCell>
                    <TableCell data-testid='summary-period-value'>
                      <FormattedMessage id='order_approval.table_value_period'
                        values={{
                          end_date: moment(policyDetails.endDate, 'DD/MM/YYYY').format('D.M.YYYY'),
                          start_date: moment(policyDetails.startDate, 'DD/MM/YYYY').format('D.M.YYYY'),
                        }}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='order_approval.table_label_product_line'/>
                    </TableCell>
                    <TableCell data-testid='summary-product-line-value'>
                      <FormattedMessage id='order_approval.table_value_product_line'
                        values={{
                          productline_title: policyDetails.selected_offer.productLineName,
                          providerName: policyDetails.selected_offer.providerName,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='order_approval.table_label_vehicle'/>
                    </TableCell>
                    <TableCell data-testid='summary-vehicle-value'>
                      <FormattedMessage id='order_approval.table_value_vehicle' values={{
                        licenseNumber: policyDetails.licenseNumber,
                        manufacturer: policyDetails.autoInfo.manufacturer,
                        model: policyDetails.autoInfo.main_car_desc,
                        year: policyDetails.autoInfo.car_year,
                      }}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='order_approval.table_label_owner'/>
                    </TableCell>
                    <TableCell data-testid='summary-owner-value'><span>{policyDetails.ownerFullName}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='order_approval.table_label_drivers'/>
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='order_approval.table_value_drivers'
                        values={{
                          driverAge: policyDetails.youngDriverAge,
                          driverGender: <FormattedMessage id={`fields.${policyDetails.youngDriverGender}`} />,
                          driverName: `${policyDetails.firstname} ${policyDetails.lastname}`,
                        }}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} container spacing={3} alignItems='center'>
            <Grid item xs={6} md={7}>
              <span className={classes.messagePrimary}>
                <FormattedMessage id='order_approval.order_confirmation_title'/>
              </span>
            </Grid>
            <Grid item xs={6} md={5}>
              <Button variant='outlined'
                color='secondary'
                size='medium'
                target='_blank' rel='noopener noreferrer'
                href=
                  {`${backendServicePrefix}/api/v2/get_policy?token=${policyDetails.offersCallId}`}>
                <Icon className={classes.downloadIcon}>get_app</Icon>
                <FormattedMessage id='order_approval.button_download'/>
              </Button>
            </Grid>
            <Grid item xs={6} md={7}>
              <span className={classes.messagePrimary}>
                <FormattedMessage id='order_approval.insurance_terms_title'/>
              </span>
            </Grid>
            <Grid item xs={6} md={5}>
              <Button variant='outlined'
                color='secondary'
                size='medium'
                href={`/policies/${policyDetails.selected_offer.productLineId}/policy.pdf`}
                target='_blank' rel='noopener noreferrer'>
                <Icon className={classes.downloadIcon}>get_app</Icon>
                <FormattedMessage id='order_approval.button_download'/>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <ShippingDetails open={isSippingOpen} onClose={setIsShippingClose}/>
      <Box borderTop={1} className={classes.borderColorGray} pt={2}>
        <SnackbarContent className={classes.alertTransparent}
          message={<FormattedMessage id='order_approval.bottom_notification'/>}/>
      </Box>
    </Grid>
  </Grid>);
};

const useStyles = makeStyles(theme => ({
  alertTransparent: {
    backgroundColor: 'transparent !important',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    fontWeight: '500',
  },
  borderColorGray: {
    borderColor: '#707070',
  },
  borderColorLight: {
    borderColor: theme.palette.secondary.light,
  },
  boxAlert: {
    backgroundColor: 'rgba(226, 226, 226, 0.16)',
    fontSize: theme.typography.pxToRem(15.75),
  },
  boxAlertMessage: {
    color: theme.palette.type === 'light' ? '#9D9D9D' : theme.palette.text.secondary,
  },
  boxAlertTitle: {
    color: theme.palette.type === 'light' ? '#8e8e8e' : theme.palette.text.secondary,
    fontWeight: 600,
    marginBottom: 8,
  },
  cellTitle: {
    borderRight: `1px solid ${theme.palette.divider}`,
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  colorAccent: {
    color: agentDeveloperUtils.getPrimaryColor(450),
  },
  downloadIcon: {
    color: agentDeveloperUtils.getPrimaryColor(450),
    fontSize: theme.typography.pxToRem(17.5),
    marginRight: 5,
  },
  messageBigLetters: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(36.75),
    fontWeight: 300,
    lineHeight: '52px',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  messageHead: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(15.75),
      lineHeight: '28px',
    },
  },
  messagePrimary: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
  },
  messageRegular: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15.75),
  },
  messageSubhead: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 500,
  },
  tableSmall: {
    fontSize: theme.typography.pxToRem(12.25),
  },
  textSmall: {
    fontSize: theme.typography.fontSize,
  },
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
}));

export default OrderApproval;
